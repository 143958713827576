/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active', // Replacing default class with bootstrap active
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component () {
        return import(/* webpackChunkName: "clientTypes" */ './views/Home.vue')
      }
    },
    {
      path: '/cpacker',
      name: 'cpacker',
      component () {
        return import(/* webpackChunkName: "cpacker" */ './views/CPacker.vue')
      }
    },
    {
      path: '/control',
      name: 'control',
      component () {
        return import(/* webpackChunkName: "control" */ './views/Control.vue')
      }
    },
    {
      path: '/control/client/:client_id',
      name: 'clientCtrl',
      component () {
        return import(/* webpackChunkName: "control" */ './views/ControlClient.vue')
      }
    },
    {
      path: '/timeline',
      name: 'timeline',
      component () {
        return import(/* webpackChunkName: "timeline" */ './views/Timeline.vue')
      }
    },
    {
      path: '/timeline/details/:cal_uuid/:rev',
      name: 'timelineDetails',
      component () {
        return import(/* webpackChunkName: "timeline" */ './views/TimelineDetails.vue')
      }
    },
    {
      path: '/power',
      name: 'power',
      component () {
        return import(/* webpackChunkName: "clients" */ './views/Power.vue')
      }
    },
    {
      path: '/drivers',
      name: 'drivers',
      component () {
        return import(/* webpackChunkName: "drivers" */ './views/Drivers.vue')
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component () {
        return import(/* webpackChunkName: "profile" */ './views/UserProfile.vue')
      }
    },
    {
      path: '/docs',
      name: 'docs',
      component () {
        return import(/* webpackChunkName: "docs" */ './views/Docs.vue')
      }
    },
    {
      path: '/notfound',
      component () {
        return import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
      }
    },
    {
      path: '/error',
      component () {
        return import(/* webpackChunkName: "error" */ './views/Error.vue')
      }
    },
    {
      path: '*',
      component () {
        return import(/* webpackChunkName: "notFound" */ './views/NotFound.vue')
      }
    }
  ]
})
