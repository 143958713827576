/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */
import Vue from 'vue'
import { BootstrapVue, BVToastPlugin, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

import CProxyDriver from 'cproxydriver'
import Exhibits from 'exhibits'
import PowervisorDriver from 'powervisordriver'
import VolumeCtlDriver from 'volumectldriver'

import brokerGrpcWebPb from 'emsapis/broker/v1/broker_grpc_web_pb'

import UserManagement from 'usermanagement'
import CMS from 'emscms'

import App from './App.vue'
import router from './router'
import { DriverManager } from 'emslib'
import KeyCloakManager from './js/keycloakmanager'
import powervisor from 'powervisorweb'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/_globals.scss'

const initAuth = async () => {
  let kcres = await fetch(process.env.BASE_URL + '/keycloak.json')
  let kcconf = await kcres.json()
  let kcmgnr = new KeyCloakManager(kcconf)
  Vue.prototype.$keycloakmanager = kcmgnr
  kcmgnr.addLoginCallback(initApp)
  kcmgnr.init()
}

function setupCms (config) {
  Vue.use(CMS, { config: config.cms })
  router.addRoutes([CMS.getRoute()])
  Vue.emsPlugins.push(CMS)
}

async function setupPowervisor () {
  const appres = await fetch(process.env.BASE_URL + 'pvconfig.json')
  const appconf = await appres.json()
  Vue.use(powervisor, {
    config: appconf,
    keycloakManager: Vue.prototype.$keycloakmanager
  })
}

async function setupI18n () {
  let response = await fetch(process.env.BASE_URL + '/ems_i18n.json')
  let messages = await response.json()
  let locale = null
  if (localStorage['ems-locale']) {
    locale = localStorage['ems-locale']
  } else {
    locale = navigator.language || navigator.userLanguage
    locale = locale.split('-')[0]
  }
  const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'en',
    messages // set locale messages
  })
  return i18n
}

function setupUserManager (config) {
  if (config.cms.ignoreGroups) {
    Vue.use(UserManagement, { ignoreGroups: config.cms.ignoreGroups })
  } else {
    Vue.use(UserManagement)
  }
  router.addRoutes([UserManagement.getRoute()])
  Vue.emsPlugins.push(UserManagement)
}

const initApp = async () => {
  let response = await fetch(process.env.BASE_URL + '/config.json')
  let config = await response.json()

  let domain = window.location.protocol + '//' + window.location.hostname
  Vue.prototype.$BROKER_PROXY = (
    domain + config.brokerProxy
  )
  Vue.prototype.$TIMELINE_MONGODB = (
    domain + config.timelineMongoDb
  )
  Vue.prototype.$SUPPORT_EMAIL = (
    config.supportEmail
  )

  Vue.prototype.$EXTERNAL_LINKS = null
  if (config.externalLinks) {
    Vue.prototype.$EXTERNAL_LINKS = (
      config.externalLinks
    )
  }

  Vue.prototype.$IS_POWER = true
  if (config.isPower !== undefined) {
    Vue.prototype.$IS_POWER = config.isPower
  }
  Vue.prototype.$IS_TIMELINE = true
  if (config.isTimeline !== undefined) {
    Vue.prototype.$IS_TIMELINE = config.isTimeline
  }
  Vue.prototype.$IS_DOCS = true
  if (config.isDocs !== undefined) {
    Vue.prototype.$IS_DOCS = config.isDocs
  }
  Vue.prototype.$IS_CLIENTS = true
  if (config.isClients !== undefined) {
    Vue.prototype.$IS_CLIENTS = config.isClients
  }
  Vue.prototype.$IS_DRIVERS = true
  if (config.isDrivers !== undefined) {
    Vue.prototype.$IS_DRIVERS = config.isDrivers
  }
  Vue.prototype.$ENABLE_CPACKER = true
  if (config.enableCPacker !== undefined) {
    Vue.prototype.$ENABLE_CPACKER = config.enableCPacker
  }

  Vue.config.productionTip = false

  Vue.use(BootstrapVue)
  Vue.use(BVToastPlugin)
  Vue.use(IconsPlugin)
  Vue.use(VueI18n)

  Vue.use(CProxyDriver)
  if (Vue.prototype.$IS_POWER) {
    Vue.use(PowervisorDriver)
  }

  Vue.use(VolumeCtlDriver)

  Vue.emsPlugins = []

  // Setup cproxy
  if (config.isExhibits !== undefined && config.isExhibits) {
    Vue.use(Exhibits)
    router.addRoutes([Exhibits.getRoute()])
    Vue.emsPlugins.push(Exhibits)
  }

  // Config cms
  setupCms(config)

  // Config powervisor
  if (Vue.prototype.$IS_POWER) {
    await setupPowervisor()
  }

  // Config user management
  setupUserManager(config)

  let i18n = await setupI18n()

  Vue.prototype.$driverManager = new DriverManager(
    Vue.prototype.$BROKER_PROXY,
    brokerGrpcWebPb,
    config.brokerTimeout
  )

  Vue.prototype.$VUE_APP_VERSION = require('../package').version

  new Vue({
    i18n,
    router,
    render (h) { return h(App) }
  }).$mount('#app')
}

initAuth()
